import React from "react"
import { Box, BoxProps, Image } from "@chakra-ui/react"

import GradientImg from "~images/background-gradient-md.svg"

const GradientImage: React.FC<{ src: string } & BoxProps> = ({
    src,
    ...props
}) => {
    return (
        <Box
            w={["300px", "400px", "500px", "600px", "700px"]}
            h={["200px", "250px", "300px", "350px", "400px"]}
            position="relative"
            {...props}
        >
            <Image
                src={GradientImg}
                w="100%"
                h="100%"
                pos="absolute"
                objectFit="cover"
                borderRadius="16px"
                bottom={{ base: "-12px", md: "-20px" }}
                right={{ base: "-12px", md: "-20px" }}
            />
            <Image
                src={src}
                w="100%"
                h="100%"
                pos="relative"
                objectFit="cover"
                borderRadius="16px"
            />
        </Box>
    )
}

export default GradientImage
