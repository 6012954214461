import React from "react"

import SEO from "../components/shared/SEO"
import HeroSection from "../components/home/hero-section"
import WhatIsMasterlockSection from "../components/home/what-is-section"
import Header from "../components/shared/header"

const IndexPage = () => {
    return (
        <>
            <SEO
                title="Home"
                description="Picking locks and wearin crocs"
                imageUrl="https://www.denofgeek.com/wp-content/uploads/2019/11/adam_sandler_in_a24s_uncut_gems.jpg?fit=1200%2C687"
            />
            <Header />
            <HeroSection />
            <WhatIsMasterlockSection />
        </>
    )
}

export default IndexPage
