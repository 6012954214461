import React from "react"
import {
    Box,
    Button,
    Center,
    Flex,
    Heading,
    Stack,
    Text,
    useColorMode,
} from "@chakra-ui/react"
import { CheckCircleIcon } from "@chakra-ui/icons"

import ResponsiveBlock from "~components/shared/responsive-block"
import { Link } from "gatsby"

const WhatIsMasterlockSection = () => {
    const { colorMode } = useColorMode()

    return (
        <ResponsiveBlock bg={colorMode === "light" ? "gray.100" : "gray.900"}>
            <Center flexDir="column" h="100vh" mb={"300px"}>
                <Heading>What Is Masterlock?</Heading>
                <Text>
                    The Masterlock App is a sports betting data aggregation app
                    to help you make smarter picks. There's lots of those, so
                    why should you care? Here's what makes us different:
                </Text>
                <Stack maxW="600px" spacing={6} mt={8}>
                    <ListItem
                        title={"We don't think too much"}
                        desc={
                            "We use Vegas' odds against them. We don't have to run huge machine learning models to get picks; we just follow trends of spreads."
                        }
                    />
                    <ListItem
                        title={"Spreads are king"}
                        desc={`All we do is track spread numbers;
                            differentials, averages, and trends. We aren't
                            flooding you with data, because then what's even
                            the point. Might as well do it yourself.`}
                    />
                    <ListItem
                        title={"All about community"}
                        desc={`Betting is fun. And one of the best ways to get
                            better and have fun is to be a part of a
                            community, which is the point of the Masterlock
                            Discord. Become a Locksmith and get pickin.`}
                    />
                    <ListItem
                        title={"We're winning"}
                        desc={`Most importantly, our picks are winning.  NFA, and gamble responsibly, but 🤷‍♂️`}
                    />
                </Stack>
                <Flex flexDir="column" mt={8}>
                    <Text fontSize="xs" color="gray.600" textAlign="center">
                        Want to learn more?
                    </Text>
                    <Link to="/whitepaper">
                        <Button>Read the Masterlock Whitepaper</Button>
                    </Link>
                </Flex>
            </Center>
        </ResponsiveBlock>
    )
}

export default WhatIsMasterlockSection

const ListItem: React.FC<{ title: string; desc: string }> = ({
    title,
    desc,
}) => (
    <Flex align="center" gridGap={8}>
        <CheckCircleIcon color="green.400" />
        <Box>
            <Text fontWeight="bold" fontSize="sm">
                {title}
            </Text>
            <Text color="gray.600">{desc}</Text>
        </Box>
    </Flex>
)
