import React from "react"
import { Button } from "@chakra-ui/button"
import { Center, Flex, Heading, Link } from "@chakra-ui/layout"

import HomepageImg from "~images/masterlock-homepage.jpg"
import ResponsiveBlock from "~components/shared/responsive-block"
import GradientImage from "../shared/gradient-image"

const HeroSection = () => {
    return (
        <>
            <ResponsiveBlock>
                <Center
                    h="90vh"
                    gridGap={{ base: 12, md: 8, lg: 12 }}
                    flexDir={{ base: "column", md: "row" }}
                >
                    <Flex flexDir="column" justify="center" align="center">
                        <Heading textAlign="center">
                            Let's make better picks.
                        </Heading>
                        <Heading
                            fontFamily="Lucida Handwriting"
                            bgGradient="linear-gradient(270deg, #7928CA, #FF0080)"
                            bgClip="text"
                            fontSize="6xl"
                            fontWeight="extrabold"
                            lineHeight="normal"
                            textAlign="center"
                        >
                            Together.
                        </Heading>
                        <Link href="https://discord.gg/UaAr8tyMCm">
                            <Button w="min-content" mt={8}>
                                Join the gd discord
                            </Button>
                        </Link>
                    </Flex>
                    <GradientImage src={HomepageImg} />
                </Center>
            </ResponsiveBlock>
        </>
    )
}

export default HeroSection
